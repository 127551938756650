import React from "react";

// Images
import d1 from "../../assets/img/1.jpg";
import d2 from "../../assets/img/2.jpg";
import d3 from "../../assets/img/3.jpg";
import d4 from "../../assets/img/4.jpg";
import d5 from "../../assets/img/5.jpg";
import d6 from "../../assets/img/6.jpg";
import d7 from "../../assets/img/7.jpg";
import d8 from "../../assets/img/8.jpg";
import d9 from "../../assets/img/9.jpg";

const TripsChild = () => {
  return (
    <>
      <section className="container mx-auto px-4 py-5">
        <h2 className="second-title text-blue">Day Trip 1.</h2>
        <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-5 items-center">
          <div className="images">
            <img className="rounded-xl" src={d1} alt="" />
            <div className=" flex gap-5 py-6 w-fit">
              <img
                className="rounded-xl"
                style={{ width: "48%" }}
                src={d2}
                alt=""
              />
              <img
                className="rounded-xl"
                style={{ width: "49%" }}
                src={d3}
                alt=""
              />
            </div>
          </div>
          <div className="info">
            <h3 className="curve-title">Split-Rizman-Dubrovnik-Mali Ston</h3>
            <p className="price">1200€</p>
            <h4>Split</h4>
            <h4>Rizman</h4>
            <h4>Dubrovnik</h4>
            <h4>Mali Ston</h4>
            <p>Suggested itinerary:</p>
            <ul>
              <li>9:00 departure from Split</li>
              <li>10.30 recommended Rizman Winery rest stop, wine tasting*</li>
              <li>12:00 Dubrovnik free time</li>
              <li>16.00 departure from Dubrovnik</li>
              <li>17:00 recommended restaurant Kapetanova kuća*</li>
              <li>18:30 departure from Mali Ston</li>
              <li>20:00 arrival at Split</li>
            </ul>
            <button className="bg-blue py-5 px-8 rounded-full text-white my-10">
              <a href="mailto:info@pickanddrop.io">Enquire now</a>
            </button>
          </div>
        </div>
        <h2 className="second-title text-blue">Day Trip 2.</h2>
        <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-5 items-center">
          <div className="images">
            <img className="rounded-xl" src={d4} alt="" />
            <div className=" flex gap-5 py-6 w-fit">
              <img
                className="rounded-xl"
                style={{ width: "48%" }}
                src={d5}
                alt=""
              />
              <img
                className="rounded-xl"
                style={{ width: "49%" }}
                src={d6}
                alt=""
              />
            </div>
          </div>
          <div className="info">
            <h3 className="curve-title">Split-Krka-Šibenik</h3>
            <p className="price">850€</p>
            <h4>Split</h4>
            <h4>Krka</h4>
            <h4>Šibenik</h4>
            <p>Suggested itinerary:</p>
            <ul>
              <li>9:00 departure from Split</li>
              <li>10:30 Krka national park*</li>
              <li>13:30 departure from Krka</li>
              <li>
                14:00 arrival at Šibenik , recommended restaurant Bronzin* and
                free time for sightseeing of Šibenik
              </li>
              <li>18:30 departure from Šibenik</li>
              <li>20:00 arrival at Split</li>
            </ul>
            <button className="bg-blue py-5 px-8 rounded-full text-white my-10">
              <a href="mailto:info@pickanddrop.io">Enquire now</a>
            </button>
          </div>
        </div>
        <h2 className="second-title text-blue">Day Trip 3.</h2>
        <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-5 items-center">
          <div className="images">
            <img className="rounded-xl" src={d7} alt="" />
            <div className=" flex gap-5 py-6 w-fit">
              <img
                className="rounded-xl"
                style={{ width: "48%" }}
                src={d8}
                alt=""
              />
              <img
                className="rounded-xl"
                style={{ width: "49%" }}
                src={d9}
                alt=""
              />
            </div>
          </div>
          <div className="info">
            <h3 className="curve-title">Split-Plitvice-Zadar</h3>
            <p className="price">1300€</p>
            <h4>Split</h4>
            <h4>Plitvice</h4>
            <h4>Zadar</h4>
            <p>Suggested itinerary:</p>
            <ul>
              <li>08.30 departure from Split</li>
              <li>
                11.00 arrival at National Park Plitvice* , free time,
                recommended lunch at Degenija*
              </li>
              <li>16:00 departure from Plitvice</li>
              <li>17.30 arrival at Zadar, free time</li>
              <li>18:30 departure from Zadar</li>
              <li>20:00 arrival at Split</li>
            </ul>
            <button className="bg-blue py-5 px-8 rounded-full text-white my-10">
              <a href="mailto:info@pickanddrop.io">Enquire now</a>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default TripsChild;
