import React from "react";
import Navbar from "../Navbar";
import transfer from "../../assets/img/transfer.jpg";
import Footer from "../Footer";
import Form from "./Form";
import Tables from "./Table";
const Header = () => {
  return (
    <>
      <Navbar />
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-10 py-24">
          <div>
            <h1 className="second-title pb-2">
              We take care of your trip<span className="text-blue">.</span>
            </h1>
            <p className="pb-10">
              Join us on amazing day trips to escape the routine. Spend the
              entire day submerging yourself in a world of exploration,
              adventure, and leisure. Our day tours are made to suit your needs,
              whether you're looking for spectacular natural beauties, cultural
              encounters, or exhilarating outdoor activities.
            </p>
            <p>
              Our knowledgeable guides will take you to the most captivating
              locations as you discover hidden jewels, experience the local
              charm, and make lifelong memories. All you have to do is sit back,
              unwind, and let us make your day trip extraordinary with
              hassle-free transportation, knowledgeable commentary, and expertly
              designed itineraries. Prepare to maximize every minute with one of
              our fantastic day trips.
            </p>
          </div>
          <img className="rounded-xl" src={transfer} alt="transfer" />
        </div>
        <Tables />
        <Form />
      </div>
      <Footer />
    </>
  );
};

export default Header;
