import Footer from "../components/Footer";
import Destination from "../components/home/Destination";
import Faq from "../components/home/Faq";
import How from "../components/home/How";
import Trips from "../components/home/Trips";
import TripsNav from "../components/home/TripsNav";
import Why from "../components/home/Why";
import Travel from "../components/trips/Travel";

const faqs = [
  {
    question: "What if I cancel my reservation?",
    answer:
      "In case of cancellation of a car or scooter rental reservation, we retain the advance payment paid to confirm the reservation.",
  },
  {
    question: "Is fuel included in price?",
    answer:
      "Fuel is not included in the price of the vehicle rental, and when returning, the vehicle must be returned with the same amount of fuel as it was picked up.",
  },
  {
    question: "Are skis or towing balloon included?",
    answer: "Skis or towing balloon, as desired",
  },
  {
    question: "Are skis or towing balloon included?",
    answer: "Skis or towing balloon, as desired",
  },
];

const Home = () => {
  return (
    <>
      <TripsNav />
      <Destination />
      <Trips />
      <Why />
      <How />
      <Footer />
    </>
  );
};

export default Home;
