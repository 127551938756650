import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const Tables = () => {
  function createData(name: string, distance: string, price: string) {
    return {
      name,
      distance,
      price,
    };
  }

  const rows = [
    createData("SPU (or reverse)", "23km", "150€"),
    createData("Split-Trogir", "27km", "170€"),
    createData("Split-Šibenik", "87km", "475€"),
    createData("Split-Makarska", "87km", "475€"),
    createData("Split-Zadar", "157km", "635€"),
    createData("Split-Dubrovnik", "232km", "950€"),
    createData("Split-Plitvice", "255km", "1000€"),
    createData("Split-Zagreb", "409km", "1200€"),
    createData("Split-Rovinj", "501km", "1475€"),
  ];

  return (
    <>
      <h2 className="second-title">
        Transfer Price<span className="text-blue">.</span>
      </h2>
      <TableContainer component={Paper} className="mb-10">
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Route</TableCell>
              <TableCell>Distance</TableCell>
              <TableCell>Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.distance}</TableCell>
                <TableCell>{row.price}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <p>
        * Price per kilometer for rides up to 50 Km is 6 €/km. Starting point is
        Split.
      </p>
      <p>
        * Price per kilometer for rides up to 100 Km is 5,5/km. Starting point
        is Split.
      </p>
      <p>
        * Price per kilometer for rides up to 300 Km is 4 €/km. Starting point
        is Split.
      </p>
      <p>
        * Price per kilometer for rides up to 700 Km is 3 €/km. Starting point
        is Split.
      </p>
      <p>
        * Price per kilometer for rides above 700 Km is 2.5 €/km. Starting point
        is Split.
      </p>
      <p>* Transfers abroad have fixed supplement 150 €/day.</p>
      <p>Waiting time: 1 h = 50 €</p>
      <p>Vehicle disposal: 1h = 100 € (minimum 4 hours)</p>
      <p>
        Example: Venice (640 km*2,5 €) + 150 € + toll’s (aprox. 60 €) = 1810 €
      </p>
    </>
  );
};

export default Tables;
