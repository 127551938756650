import React from "react";
import Trip1 from "../../assets/img/2.jpg";
import Trip2 from "../../assets/img/5.jpg";
import Trip3 from "../../assets/img/8.jpg";

import time from "../../assets/img/icons/time.svg";
import pin from "../../assets/img/icons/pin.svg";
import launch from "../../assets/img/icons/launch.svg";
import compas from "../../assets/img/icons/compas.svg";

interface Trips {
  name: string;
  image: {
    src: any;
    alt: string;
  };
  detail1: string;
  detail2: string;
  detail3: string;
  detail4: string;
}

const trips: Trips[] = [
  {
    name: "Split-Rizman-Dubrovnik-Mali Ston",
    image: {
      src: Trip1,
      alt: "Trip to Dubrovnik",
    },
    detail1: "Showlaces",
    detail2: "Shoplaces",
    detail3: "3",
    detail4: "Shoplaces",
  },
  {
    name: "Split-Krka-Šibenik",
    image: {
      src: Trip2,
      alt: "Trip to Dubrovnik",
    },
    detail1: "Showlaces",
    detail2: "Shoplaces",
    detail3: "Shoplaces",
    detail4: "Shoplaces",
  },
  {
    name: "Split-Plitvice-Zadar",
    image: {
      src: Trip3,
      alt: "Trip to Dubrovnik",
    },
    detail1: "Showlaces",
    detail2: "Shoplaces",
    detail3: "Shoplaces",
    detail4: "Shoplaces",
  },
];

const Trips = () => {
  return (
    <section className="trips container mx-auto px-4">
      <h2 className="curve-title">Trips</h2>
      <div className="travel grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 py-5">
        {trips.map((el: any, index: any) => (
          <div className="parent-travel rounded-xl" key={index}>
            <div className="travel-item">
              <img
                className="rounded-xl w-full"
                src={el.image.src}
                alt={el.image.alt}
              />
              <div className="travel">
                <h4>{el.name}</h4>
                <div className="travel-desc">
                  <div className="travel-wrapper">
                    <div className="wrap">
                      <img src={time} alt="" />
                      {/* <p>8 hours</p> */}
                    </div>
                    <div className="wrap">
                      <img src={launch} alt="" />
                      <p>Launch time</p>
                    </div>
                  </div>
                  <div className="travel-wrapper">
                    <div className="wrap">
                      <img src={pin} alt="" />
                      <p>3 stops</p>
                    </div>
                    <div className="wrap">
                      <img src={compas} alt="" />
                      <p>Navigation</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Trips;
