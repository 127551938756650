import React from "react";

// Image imports
import clouds from "../../assets/img/clouds.webp";
const Header = () => {
  return (
    <section className="container mx-auto px-4 py-5 h-screen grid md:grid-cols-1 lg:grid-cols-2 items-center gap-5">
      <div className="content-section">
        <h1 className="second-title pb-2">
          We take care of your trip<span className="text-blue">.</span>
        </h1>
        <p className="pb-10">
          Join us on amazing day trips to escape the routine. Spend the entire
          day submerging yourself in a world of exploration, adventure, and
          leisure. Our day tours are made to suit your needs, whether you're
          looking for spectacular natural beauties, cultural encounters, or
          exhilarating outdoor activities.
        </p>
        <p>
          Our knowledgeable guides will take you to the most captivating
          locations as you discover hidden jewels, experience the local charm,
          and make lifelong memories. All you have to do is sit back, unwind,
          and let us make your day trip extraordinary with hassle-free
          transportation, knowledgeable commentary, and expertly designed
          itineraries. Prepare to maximize every minute with one of our
          fantastic day trips.
        </p>
        <button className="bg-blue py-3 mt-10 px-7 rounded-full">
          <a className="text-white" href={"mailto:info@pickanddrop.io"}>
            Enquire now
          </a>
        </button>
      </div>
      <img src={clouds} alt="" />
    </section>
  );
};

export default Header;
