import React from "react";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Form = () => {
  function sentEmail(e: any) {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_vdrsu5u",
        "template_k6f4q06",
        e.target,
        "xt3uuHOrHN9GJB8cr"
      )
      .then(
        (result) => {
          toast.success("Email sent successfully!");
          console.log(result.text);
        },
        (error) => {
          toast.error("Failed to send email. Please try again.");
          console.log(error.text);
        }
      );
  }

  return (
    <>
      <ToastContainer />
      <h2 className="second-title text-center">
        Simple<span className="text-blue">.</span>
      </h2>
      <p className="text-center py-5">
        Type where you want to go and we shuttle you there.
      </p>
      <div className="sm:w-full lg:w-2/3 xl:w-1/2 mx-auto pb-24">
        <form action="" onSubmit={sentEmail} className="flex flex-col">
          <input type="text" name="start" placeholder="Starting place" />
          <input type="text" name="end" placeholder="Final destination" />
          <input type="email" name="email" placeholder="Email" />
          <input
            type="number"
            name="number"
            placeholder="Number of passengers"
          />
          <input type="date" name="date" placeholder="Date of travel" />
          <button
            type="submit"
            name="submit"
            className="bg-blue text-white py-3 mt-10 px-7 rounded-full"
          >
            Enquire now
          </button>
        </form>
      </div>
    </>
  );
};

export default Form;
